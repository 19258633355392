import styled, { keyframes } from 'styled-components'
import { Balance } from '@pancakeswap/uikit'
// import { LotteryStatus } from 'config/constants/types'
// import { useTranslation } from '@pancakeswap/localization'
import { usePriceCakeUSD } from 'state/farms/hooks'
import { useLottery } from 'state/lottery/hooks'
import { getBalanceNumber } from '@pancakeswap/utils/formatBalance'
import BuyTicketsButton from './BuyTicketsButton'
// import ticketImage from '../../Lottery/Assests/Images/Hashfair Ticket.png'
// import Svg from "../Svg";
// import { SvgProps } from "../types";

export const floatingStarsLeft = keyframes`

  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(10px, 10px);
  }
  to {
    transform: translate(0, -0px);
  }
`

export const floatingStarsRight = keyframes`
  from {
    transform: translate(0,  0px);
  }
  50% {
    transform: translate(-10px, 10px);
  }
  to {
    transform: translate(0, -0px);
  }
`
 const Backgroundlottery = styled.div`
.confetti {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: -1;
}
.confetti-piece {
  position: absolute;
  width: 8px;
  height: 16px;
  top: 0;
  opacity: 0;
  animation: makeItRain 5000ms infinite ease-out;
}
/* Assign different colors to each piece */
.confetti-piece:nth-child(1) {
  left: 7%;
  background: #ff4500;
  transform: rotate(-79deg);
  animation-delay: 1s;
  animation-duration: 2s;
}
.confetti-piece:nth-child(2) {
  left: 14%;
  background: #ff6347;
  transform: rotate(-42deg);
  animation-delay: 2s;
  animation-duration: 2.5s;
}
.confetti-piece:nth-child(3) {
  left: 21%;
  background: #ffd700;
  transform: rotate(-22deg);
  animation-delay: 1.5s;
  animation-duration: 2.8s;
}
.confetti-piece:nth-child(4) {
  left: 28%;
  background: #adff2f;
  transform: rotate(-62deg);
  animation-delay: 2.2s;
  animation-duration: 2.3s;
}
.confetti-piece:nth-child(5) {
  left: 35%;
  background: #1e90ff;
  transform: rotate(-12deg);
  animation-delay: 1.8s;
  animation-duration: 2.7s;
}
.confetti-piece:nth-child(6) {
  left: 42%;
  background: #da70d6;
  transform: rotate(-45deg);
  animation-delay: 1.3s;
  animation-duration: 3s;
}
.confetti-piece:nth-child(7) {
  left: 49%;
  background: #ff1493;
  transform: rotate(-33deg);
  animation-delay: 2.1s;
  animation-duration: 2.4s;
}
.confetti-piece:nth-child(8) {
  left: 56%;
  background: #00fa9a;
  transform: rotate(-17deg);
  animation-delay: 1.6s;
  animation-duration: 2.2s;
}
.confetti-piece:nth-child(9) {
  left: 63%;
  background: #20b2aa;
  transform: rotate(-25deg);
  animation-delay: 2.4s;
  animation-duration: 3.2s;
}
.confetti-piece:nth-child(10) {
  left: 70%;
  background: #ffa500;
  transform: rotate(-9deg);
  animation-delay: 1.4s;
  animation-duration: 2.9s;
}
.confetti-piece:nth-child(11) {
  left: 77%;
  background: #ff00ff;
  transform: rotate(-55deg);
  animation-delay: 2s;
  animation-duration: 2.6s;
}
.confetti-piece:nth-child(12) {
  left: 84%;
  background: #9400d3;
  transform: rotate(-34deg);
  animation-delay: 1.7s;
  animation-duration: 2.5s;
}
.confetti-piece:nth-child(13) {
  left: 91%;
  background: #00ced1;
  transform: rotate(-28deg);
  animation-delay: 1.9s;
  animation-duration: 2.3s;
}

@keyframes makeItRain {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    transform: translateY(100vh);
  }
}


`

// const TicketContainer = styled(Flex)``

// const PrizeTotalBalance = styled(Balance)`
//   background: ${({ theme }) => theme.colors.gradientGold};
//   -webkit-background-clip: text;
//   -webkit-text-fill-color: transparent;
// `

// const StyledBuyTicketButton = styled(BuyTicketsButton)<{ disabled: boolean }>`
//   color: #fff;
//   width: 150px;
//   height: 55px;
//   border-radius: 45px;
//   border: none;

//   // background: rgb(34,193,195);
//   // background: linear-gradient(to top, #eb7dff, #dab1ff);
//   font-weight: initial;
//   color: white;

//   cursor: pointer;
//   display: -webkit-box;
//   display: -webkit-flex;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-align-items: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -webkit-box-pack: center;
//   -webkit-justify-content: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   &:hover {
//     opacity: 1 !important;
//   }
//   ${({ theme }) => theme.mediaQueries.xs} {
//     // width: 240px;
//   }
// `

// const ButtonWrapper = styled.div`
//   z-index: 1;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// `

// const Banner = styled.div`
//   position: relative;
// `
// const LeftDiv = styled.div`
//   position: relative;
// `

const Hero = () => {
  const {
    currentRound: { amountCollectedInCake },
  } = useLottery()

  const cakePriceBusd = usePriceCakeUSD()
  const prizeInBusd = amountCollectedInCake.times(cakePriceBusd)
  const prizeTotal = getBalanceNumber(prizeInBusd)
  // const ticketBuyIsDisabled = status !== LotteryStatus.OPEN || isTransitioning

  // const getHeroHeading = () => {
  //   if (status === LotteryStatus.OPEN) {
  //     return (
  //       <>
  //         {prizeInBusd.isNaN() ? (
  //           <Skeleton my="7px" height={60} width={190} />
  //         ) : (
  //           <PrizeTotalBalance fontSize="64px" unit=" EXBIT" value={prizeTotal} mb="8px" decimals={0} />
  //         )}
  //         <Heading mb="32px" scale="lg" color="#ffffff">
  //           {t('in prizes!')}
  //         </Heading>
  //       </>
  //     )
  //   }
  //   return (
  //     <Heading mb="24px" scale="xl" color="#ffffff" textAlign="center">
  //       {t('Tickets on sale soon')}
  //     </Heading>
  //   )
  // }
  // const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {

  return (
    <Backgroundlottery>
<div className="confetti">
  <div className="confetti-piece" />
  <div className="confetti-piece" />
  <div className="confetti-piece" />
  <div className="confetti-piece" />
  <div className="confetti-piece" />
  <div className="confetti-piece" />
  <div className="confetti-piece" />
  <div className="confetti-piece" />
  <div className="confetti-piece" />
  <div className="confetti-piece" />
  <div className="confetti-piece" />
  <div className="confetti-piece" />
  <div className="confetti-piece" />
</div>
    <div className="ticketimage">
      <div className="imgticket">
   
        <img style={{ width: '' }} alt="ticket-buy" className='desktopticket' src="/buyticket.png" />
        <img style={{ width: '' }} alt="ticket-buy" className='mediaticket' src="/mobilebuyticket.png" />

        <div className="desktoptickettext">
          <div className="hfgtext ticketprice">{prizeTotal.toFixed(2)} EXBIT</div>
          <h2 className='tickettittleprize'> in prizes!</h2>
          <div className="ticketbtn ">
            <a type="button" href='#buy-tickets' className="btntickets">
              buy ticket
            </a>
          </div>
        </div>
        <div className="mobiletickettext">
          <div className="mobilehfgtext ticketprice">{prizeTotal.toFixed(2)} EXBIT</div>
          <h2 className='mobiletickettittleprize'> in prizes!</h2>
          <div className="ticketbtn ">
            <a type="button" href='#buy-tickets' className="mobilebtntickets">
              buy ticket
            </a>
          </div>
        </div>
      </div>
    </div>
    </Backgroundlottery>
  )
}

export default Hero

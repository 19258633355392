import React from 'react';
import styled from 'styled-components';
import { Text, Flex } from '@pancakeswap/uikit';

// Ball text wrapper styling
export const BallTextWrapper = styled.div`
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  font-size: 25px;
`;

// Ball text styling
export const BallText = styled(Text)<{ rotationTransform?: number }>`
  color: #F4EEFF;
  font-size: inherit;
  transform: rotate(${({ rotationTransform }) => rotationTransform || 0}deg);
`;

// Define BallColor types
export type BallColor = 'pink' | 'lilac' | 'teal' | 'aqua' | 'green' | 'yellow';

interface BallWithNumberProps {
  color: BallColor;
  number: string;
  size?: string;
  fontSize?: string;
  rotationTransform?: number;
  
}

// Component for rendering a colored ball
export const BallWithNumber: React.FC<BallWithNumberProps> = ({
  color,
  number,
  size = '32px',
  fontSize = '25px',
  rotationTransform,
}) => {
  const colorMap: Record<BallColor, string> = {
    pink: 'radial-gradient(circle at 65% 15%, white 1px, #FFC0CB 3%, #FF1493 60%, #FFC0CB 100%)', // Pink radial gradient
    lilac: 'radial-gradient(circle at 65% 15%, white 1px, #C8A2C8 3%, #8A2BE2 60%, #C8A2C8 100%)', // Lilac radial gradient
    teal: 'radial-gradient(circle at 65% 15%, white 1px, #008080 3%, #20B2AA 60%, #008080 100%)', // Teal radial gradient
    aqua: 'radial-gradient(circle at 65% 15%, white 1px, aqua 3%, darkblue 60%, aqua 100%)', // Aqua radial gradient
    green: 'radial-gradient(circle at 65% 15%, white 1px, #00FF00 3%, #228B22 60%, #00FF00 100%)', // Green radial gradient
    yellow: 'radial-gradient(circle at 65% 15%, white 1px, #FFD700 3%, #B8860B 60%, #FFD700 100%)', // Yellow radial gradient
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      position="relative"
      mx="2px"
      className="ballwrap "
      style={{
        width: size,
        height: size,
        borderRadius: '50%',
        background: colorMap[color], // Apply gradient background
      }}
    >
      <BallTextWrapper>
        <BallText rotationTransform={rotationTransform} style={{ fontSize }}>
          {number}
        </BallText>
      </BallTextWrapper>
    </Flex>
  );
};

// Mapping numbers to BallColor
const numberToColor: Record<string, BallColor> = {
  '0': 'pink',
  '1': 'lilac',
  '2': 'teal',
  '3': 'aqua',
  '4': 'green',
  '5': 'yellow',
  '6': 'pink',
  '7': 'lilac',
  '8': 'teal',
  '9': 'aqua',
};

interface WinningNumbersProps {
  number: string;
  size?: string;
  fontSize?: string;
  rotateText?: boolean;
}

// Component for rendering a sequence of winning numbers
export const WinningNumbers: React.FC<WinningNumbersProps> = ({
  number,
  size = '64px',
  fontSize = '25px',
  rotateText,
}) => {
  const numArray = number.split('');

  return (
    <Flex justifyContent="space-between">
      {numArray.map((num) => {
        const color = numberToColor[num] || 'pink'; // Default to 'pink' if undefined
        return (
          <BallWithNumber
            key={`ball-${num}-${Math.random()}`}
            color={color}
            number={num}
            size={size}
            fontSize={fontSize}
            rotationTransform={rotateText ? Math.random() * 30 - 15 : 0}
          />
        );
      })}
    </Flex>
  );
};

export default WinningNumbers;
